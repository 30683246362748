
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Label from 'components/label/Label';

// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableFooter,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Paper
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Scrollbar from '../components/scrollbar';
import { styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { getExisitingPlanUsers } from 'api/users';
import { useAuthDispatch } from 'components/AuthStateProvider';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function PlanUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const isLg = useResponsive('up', 'md');
  const { account } = useAuthDispatch();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
  }));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (status = '', type = '') => {
    try {
      const response = await getExisitingPlanUsers({ status, type });
      console.log(response)
      setLoading(false);
      if (response?.success) {
        setData(response?.data || []);
        setFilteredData(response?.data || []);
      } else {
        enqueueSnackbar(response?.message, { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar("Unable to fetch B2B users.", { variant: 'error' });
    }
  };

  const handleSubTypeChange = (event) => {
    const value = event.target.value;
    setSubscriptionType(value);
    fetchData(subscriptionStatus, value);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setSubscriptionStatus(value);
    fetchData(value, subscriptionType);
  };

  return (
    <>
      <Helmet>
        <title> Active Users</title>
      </Helmet>

      <Container sx={{ marginTop: isLg ? 0 : 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between"  mb={2}>
          <Typography variant="h4" gutterBottom>
            Active Users
          </Typography>
         
          <Stack direction="row" alignItems="center" spacing={2} >
          <FormControl sx={{ minWidth: 200 }} size="medium">
            <InputLabel id="demo-select-small-label">Subscription Type</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={subscriptionType}
              label="Subscription Type"
              onChange={handleSubTypeChange}
            >
             
              <MenuItem value={"trial"}>Trial</MenuItem>
              <MenuItem value={"paid"}>Paid</MenuItem>
              <MenuItem value={""}>None</MenuItem>
            </Select>
            <FormHelperText>*select subscription type</FormHelperText>
          </FormControl>

          <FormControl sx={{ minWidth: 200 }} size="medium">
            <InputLabel id="demo-select-small-label">Subscription Status</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={subscriptionStatus}
              label="Subscription Status"
              onChange={handleStatusChange}
            >
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"expired"}>Expired</MenuItem>
              <MenuItem value={""}>None</MenuItem>
            </Select>
            <FormHelperText>*select subscription status</FormHelperText>
          </FormControl>
          </Stack>

        </Stack>
        <Card>
          <Box>
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Scrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 100 }}> Plan Name</TableCell>
                        <TableCell sx={{ minWidth: 150 }}>Phone No.</TableCell>
                        <TableCell sx={{ minWidth: 150 }}>Plan Created</TableCell>
                        <TableCell sx={{ minWidth: 100 }}>Plan Expiry</TableCell>
                        <TableCell sx={{ minWidth: 100 }}> Subscription Type</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    {filteredData.length ? (
                      <TableBody>
                        {filteredData?.map((row, index) => (
                          <TableRow hover key={index} tabIndex={-1} style={{ cursor: 'pointer' }}>
                            <TableCell>
                              <OverflowTypography variant="subtitle2" noWrap>
                                {row?.plan_name}
                              </OverflowTypography>
                            </TableCell>
                            <TableCell>
                              <OverflowTypography variant="subtitle2" noWrap>
                                {row?.phone_number}
                              </OverflowTypography>
                            </TableCell>
                            <TableCell>
                              <OverflowTypography variant="subtitle2" noWrap>
                                {row?.created_at_formatted}
                              </OverflowTypography>
                            </TableCell>
                            <TableCell>
                              <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                {row?.expiry_date_formatted}
                              </OverflowTypography>
                            </TableCell>
                            <TableCell>
                              <OverflowTypography variant="subtitle2" noWrap>
                                {row?.subscription_type}
                              </OverflowTypography>
                            </TableCell>
                            <TableCell>
                            <Label color={new Date(row?.expiry_date) > new Date() ? 'success' : 'error'}>
                                {new Date(row?.expiry_date) > new Date() ? 'Active' : 'Expired'}
                              </Label>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableFooter>
                        <TableRow>
                          <TableCell style={{ width: '100%' }} align="center" colSpan={6}>
                            <Typography mt={2}>No user found.</Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Scrollbar>
              )}
            </Box>
          </Box>
        </Card>
      </Container>
    </>
  );
}
