// @mui material components
import {
  CircularProgress,
  Backdrop,
  Grid,
  ButtonGroup,
  Button,
  Box,
  SwipeableDrawer,
  styled,
  Typography,
  Skeleton,
  CssBaseline,
  Paper,
  Link
} from "@mui/material";
import { Global } from '@emotion/react';
import { grey } from '@mui/material/colors';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Interpretation dashboards components
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useRef, useState } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import PanelResizeHandle from "hooks/ResizeHandler";
import PagingControl from "components/PagingControl";
import panelStyles from "panel-style.module.css";
import dayjs from "dayjs";
import { useNavigate, useLocation, useParams } from "react-router-dom";
// Data
import Label from "components/label/Label";
import CustomInputLabel from "components/custom-input-label";
import CustomReportInputForm from "layouts/report-input-form/custom-report-input-form";
import { downloadFromURI, base64ToBlob, viewBlobFile } from "utils/Utils";
import { getEcgData, getEcgReportAndData } from "api/reports";
import { saveDataInterpretation } from "api/reports";
import {useSnackbar} from '../components/snackbar'
import useResponsive from "hooks/useResponsive";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const drawerBleeding = 54;
const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const props = { window: () => Window }

function Custominterpretation(props) {
  const { enqueueSnackbar } = useSnackbar(); 
  const navigate = useNavigate();
  const { id } = useParams();
  const isLg = useResponsive('up', 'md');
  const isMd = useResponsive('between', 'sm', 'md');
  console.log(isMd)
  // edit pdf
  const documentRef = useRef(null);
  const [allFilesData, setAllFilesData] = useState([]);
  const [renderedFileIndex, setRenderedFile] = useState(0);
  const [pdf, setPdf] = useState(null);
  const [importError, setImportError] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [renderAllPages, setRenderAllPages] = useState(false);
  const [pdfScale, setPdfScale] = useState(isLg || isMd ? 1 : 0.5);
  const [loading, setLoading] = useState(false);
  const [loadingdata, setLoadingdata] = useState(true);

  ////////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reportresponse = await getEcgData({
          id
        
        })
        const reportdata = reportresponse?.data;
        const fetchedPdfData = reportdata?.ReportPdf;
        setLoadingdata(false)
        delete reportdata?.ReportPdf;
        if (!reportdata || !fetchedPdfData) {
          return setImportError("Unable to fetch report. Please try again.");
        }
        const URL = `data:application/pdf;base64,${fetchedPdfData}`;
        setPdf(URL);
        setAllFilesData([reportdata]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  /////////////////////////////////////////////////
  const styles = {
    dropContainer: {},
    container: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    sigBlock: {
      display: "inline-block",
    },
    documentBlock: {
      // maxWidth: 800,
      margin: "0px",
      marginTop: 5,
      // border: "1px solid #999",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      height: "90vh",
      overflow: "auto",
    },
    controls: {
      backgroundColor: "#ffff",
      // maxWidth: 800,
      margin: "10px",
      display: "flex",
      justifyContent: "space-between",
      position: "sticky",
      zIndex: 2,
      width: "100%"
    }
  };

  //Handle form submit
  const handleReportInputFormSubmit = async (event, formInput) => {
    event.preventDefault();
    const dataToUpdate = {
      ...formInput,
      name: `${formInput["first_name"]}${formInput["last_name"]}`,
      completed_at: allFilesData[renderedFileIndex]?.completed_at || new Date(),
    };
    const bodyData = {
      id: allFilesData[renderedFileIndex]?.id,
      interpretationFormData: dataToUpdate,
    };
    const updateInterpretationData = async () => {
      try {
        const response = await saveDataInterpretation({
          bodyData
        });
        setLoadingdata(false);
        if (response && response?.status === 200 && response?.data) {
          const reportdata = response?.data;
          const fetchedPdfData = reportdata?.ReportPdf;
          delete reportdata.ReportPdf;
          const URL = `data:application/pdf;base64,${fetchedPdfData}`;
          setPdf(URL);
          enqueueSnackbar(response.message, { variant: 'success' })

          if (reportdata?.interpretation_data) setAllFilesData((previousStatus) => [{ ...previousStatus[0], interpretation_data: reportdata?.interpretation_data }]);
        } else {
          enqueueSnackbar(response.message, { variant: 'error' })
        }
      } catch (error) {
        console.error("Error updating interpretation data:", error);
      }
    };
    await updateInterpretationData();
  }


  const handleShareClick = async (e) => {
    const reportPdf = await handleReportInputFormSubmit(e, allFilesData[renderedFileIndex]);
    setLoading(true);
    if (reportLink["url"]) {

      const pdfLink = reportLink["url"];
      // Create the WhatsApp message with the PDF link
      const message = encodeURIComponent(
        `Dear ${allFilesData[renderedFileIndex]["FirstName"]},\n\nYour ECG reports is now generated.\nClick here to download - \n${pdfLink}\n\n*Thanks*,\n*Team Spandan ECG*.\nwww.spandan.in`
      );

      // Open WhatsApp with the pre-filled message
      window.open("https://wa.me/?text=" + message, "_balnk");
    } else console.error(reportLink["error"]);
    setLoading(false);
  };

  function downloadURI(uri) {
    const reportdata = allFilesData[0];
    const fileName = `${reportdata?.user_data?.first_name}${reportdata?.user_data?.last_name ? "-" + reportdata?.user_data?.last_name : ''}-${dayjs(reportdata?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${ reportdata?.interpretation_data?.report_status ? `-${reportdata?.interpretation_data?.report_status}` : '' }`;
    downloadFromURI(uri, fileName)
    enqueueSnackbar("pdf downloading successfully", { variant: 'success' })
  }

  const viewOriginalReport = (id) => async (e) => {
    try {
      setLoadingdata(true)
      const reportresponse = await getEcgReportAndData({
        id
      })
      const reportdata = reportresponse?.data;
      const fetchedPdfData = reportdata?.ReportPdf;
      setLoadingdata(false)
      delete reportdata?.ReportPdf;
      if (!reportdata || !fetchedPdfData) {
        return setImportError("Unable to download. Please try again.");
      }
      const uri = `data:application/pdf;base64,${fetchedPdfData}`;
      const fileName = `ecg-report-${reportdata?.user_data?.first_name}${reportdata?.user_data?.last_name ? `-${reportdata?.user_data?.last_name}` : ''}-${dayjs(reportdata?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${ reportdata?.interpretation_data?.report_status ? `-${reportdata?.interpretation_data?.report_status}` : '' }`;
      downloadFromURI(uri, fileName)
      enqueueSnackbar("pdf downloading successfully", { variant: 'success' })
  
      // const blobFile = await base64ToBlob(`data:application/pdf;base64,${fetchedPdfData}`);
      // viewBlobFile(blobFile);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleBackClick = (e) => {
    setPdf(null);
    setTotalPages(0);
    setPageNum(0);
    setAllFilesData([]);
    setImportError("");
    setLoading(false);
    setRenderedFile(0);
    navigate(-1);
  };


  const handleZoom = (scale) => {
    setPdfScale(scale <= 1 ? (isLg ? 1 : 0.5) : scale)
  };

  const { window } = props;
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(90% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <Grid mt={1}>
        {loadingdata ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '300px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid item xs={12} lg={12}>
            {pdf ? (
              <PanelGroup direction="horizontal" className={panelStyles.PanelGroup}>
                <Panel defaultSize={ isLg ? 55 : 100} minSize={ isLg ? 48 : 100 } maxSize={ isLg ? 60 : 100 } className={panelStyles.PanelPdf}>
                  <div>
                    <div style={{ ...styles.controls, alignItems: 'center' }}>
                      <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button size="small"
                          onClick={handleBackClick}
                          startIcon={<ArrowBackIcon />}>
                          Back
                        </Button>
                        {pdf ? (
                          (allFilesData || []).length &&
                            allFilesData[renderedFileIndex]["id"] ? (
                            <>
                              <Button size="small"
                                onClick={() => {
                                  downloadURI(pdf);
                                }}
                                startIcon={<DownloadIcon />}>
                                Download
                              </Button>
                            </>
                          ) : (
                            ""
                          )
                        ) : null}
                        {/* {(allFilesData || []).length &&
                          allFilesData[renderedFileIndex]["interpretation_request_status"] === "completed"|| showShare ? (
                          <Button size="small"reques
                            onClick={handleShareClick}
                            startIcon={<ShareIcon />}
                          >
                            Share Report
                          </Button>
                        ) : (
                          ""
                        )} */}
                      </ButtonGroup>
                      <Link variant="subtitle2" onClick={ viewOriginalReport(id) } sx={{ cursor: 'pointer' }}>
                        Download app report
                      </Link>
                      <div>
                        {!renderAllPages ? (
                          <PagingControl
                            className={panelStyles.PanelControl}
                            pageNum={pageNum}
                            setPageNum={setPageNum}
                            totalPages={totalPages}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div ref={documentRef} style={styles.documentBlock}>
                      <TransformWrapper
                        initialScale={ 1 }
                        smooth={true}
                        wheel={{ step: 0.1, animationType: 'easeOut' }}
                        pinch={{ step: 0.1, animationType: 'easeOut' }}
                        doubleClick={{ step: 0.1, animationType: 'easeOut' }}
                        onTransformed={(ref, scaleState) => {
                          handleZoom(scaleState?.scale)
                        }} >
                        <TransformComponent>
                          <Document
                            file={pdf}
                            onLoadSuccess={(data) => {
                             
                              setTotalPages(data.numPages);
                              setPageNum(data.numPages > 1 ? 1 : 0);
                            }}
                          >
                            <Page
                              renderAnnotationLayer={false}
                              renderTextLayer={false}
                              pageNumber={pageNum + 1}
                              width={750}
                              scale={ pdfScale }
                              onLoadSuccess={(data) => {
                                // setPageDetails(data);
                              }}
                            />
                          </Document>
                        </TransformComponent>
                      </TransformWrapper>
                    </div>
                  </div>
                </Panel>
               
               
                { isLg ?
                  <><PanelResizeHandle />
                  <Panel defaultSize={30} minSize={20} className={panelStyles.Panel} >
                    {allFilesData.length ? (
                      <div >

                        <CustomReportInputForm
                          formKey={renderedFileIndex}
                          displayData={{ report_timestamp: allFilesData[renderedFileIndex]?.report_timestamp }}
                          inputData={{ ...allFilesData[renderedFileIndex]?.user_data, ...allFilesData[renderedFileIndex]?.interpretation_data, interpretation_request_status: allFilesData[renderedFileIndex]?.interpretation_request_status }}
                          handleReportInputFormSubmit={handleReportInputFormSubmit}
                        ></CustomReportInputForm>
                      </div>
                    ) : (
                      ""
                    )}
                  </Panel></> :
                  <SwipeableDrawer
                    container={container}
                    anchor="bottom"
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    <StyledBox
                      sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        backgroundColor: "#cccccc",
                        right: 0,
                        left: 0,
                      }}
                    >
                      <Puller />
                      <Typography sx={{ pt: 3, pb: 1, px: 2, color: 'text.secondary' }}>
                        {allFilesData[renderedFileIndex]?.interpretation_data?.completed_at ? (
                          <Typography variant="body2" textAlign="center">
                            {`By: `}<b>{allFilesData[renderedFileIndex]?.interpretation_data?.interpreter_details?.name}</b>
                            <Typography variant="body2" display="inline">
                            {` at `}<b>{dayjs(parseInt(allFilesData[renderedFileIndex]?.interpretation_data?.completed_at)).format("DD MMM hh:mm A")}</b>
                            </Typography>
                          </Typography>
                        ) : 

                        <Typography variant="body2" textAlign="center">
                          Click to interpret
                        </Typography>
                        }
                      </Typography>
                    </StyledBox>
                    <StyledBox
                      sx={{
                        px: 2,
                        py: 2,
                        height: '100%',
                        overflow: 'auto',
                      }}
                    >
                      {allFilesData.length ? (
                        <CustomReportInputForm
                          formKey={renderedFileIndex}
                          displayData={{ report_timestamp: allFilesData[renderedFileIndex]?.report_timestamp }}
                          inputData={{ ...allFilesData[renderedFileIndex]?.user_data, ...allFilesData[renderedFileIndex]?.interpretation_data, interpretation_request_status: allFilesData[renderedFileIndex]?.interpretation_request_status }}
                          handleReportInputFormSubmit={handleReportInputFormSubmit} />
                      ) : null }
                    </StyledBox>
                  </SwipeableDrawer>
                }
              </PanelGroup>
            ) : importError ? (
              <div className={panelStyles.invalidReportBlock}>
                {/* <p>{importError}</p>
              <Button
                margin={10}
                variant="gradient"
                circular="true"
                color="info"
                onClick={handleBackClick}
              >
                <Icon>arrow_back</Icon>&nbsp; Back to Home
              </Button> */}
              </div>
            ) : (
              ""
            )
            }
          </Grid >
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid >
    </Root>
  );
}

export default Custominterpretation;