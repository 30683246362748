import { useMemo } from 'react';
// utils
import { fetcher, endpoints, poster, patcher } from '../utils/axios';

export async function getAllReports(payload){
    const URL = endpoints.reports.list
    return await fetcher([URL, {params:payload}] )
}

export async function updateReportStatus(payload) {
    const URL = endpoints.interpretation.requestInterpretation;
    return await poster([URL,payload])
}
export async function revokeInterpretationRequest(payload) {
    const URL = endpoints.interpretation.revokeInterpretationRequest;
    return await poster([URL,payload])
}
export async function getEcgData(payload) {
    
    const URL = endpoints.data.getEcgData
    console.log(URL+`${payload.id}`)
    return await fetcher([ URL+`${payload.id}`])
}
export async function getEcgReportAndData(payload) {
    const URL = endpoints.data.getEcgReportAndData
    return await fetcher([ URL+`${payload.id}`])
}
export async function saveDataInterpretation(payload) {
    const URL = endpoints.interpretation.saveDataInterpretation;
    return await patcher([ URL,payload.bodyData])
}
// export async function getB2Busers(payload) {
//     const URL = endpoints.loginProcess.getB2Busers
//     //Path Params 
//     return await fetcher([URL+`${""}`])
// }
export async function getSpecificInvoiceData(payload){
    const URL = endpoints.data.getSpecificInvoiceData
    return await fetcher([URL, { params: payload }])
}

export async function downloadLeadFile(payload) {
    const URL = endpoints.data.downloadLeadFile;
    return await fetcher([URL, { params: payload }])
}