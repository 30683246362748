import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  Chip,
  TableHead,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  ButtonGroup,
  TableContainer,
  Container,
  Typography,
  TableFooter,
  Tooltip,
  Link,
  Button,
  Divider,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Paper


} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useTheme } from '@mui/material/styles';

// components
import Scrollbar from '../components/scrollbar';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { getAllReports, downloadLeadFile } from 'api/reports';
import { useParams } from "react-router-dom";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function RDReports() {
  const isLg = useResponsive('up', 'md');
  const isSmall = useResponsive('up', 'md');
  const { id } = useParams();
  const [time, setTime] = useState('Recent');
  const [status, setStatus] = useState('');
  const [data, setData] = useState([]);
  const [openDialong, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [reportType, setReportType] = useState('')
  const [datePicker, setDatePicker] = useState(null);





  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'right',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  }));

  const leads = {
    lead1_data: 'Lead I data',
    lead2_data: 'Lead II data',
    lead3_data: 'Lead III data',
    avf_data: 'AVF data',
    avl_data: 'AVL data',
    avf_data: 'AVF data',
    v1_data: 'V1 data',
    v2_data: 'V2 data',
    v3_data: 'V3 data',
    v4_data: 'V4 data',
    v5_data: 'V5 data',
    v6_data: 'V6 data',

  };

  useEffect(() => {
    fetchReports();
  }, [time, id, datePicker])



  useEffect(() => {
    filterData()
  }, [data, status, reportType, searchTerm, datePicker])

  const filterData = () => {
    let filtered = data;

    if (status && status !== 'None') {
      filtered = filtered.filter((report) => {
        if ((report.report_type == 'REPORT_TWELVE_LEAD' || report.report_type == 'REPORT_LEAD_TWO' || report.report_type == 'REPORT_SEVEN_LEAD') && report.conclusions.ecg_type.includes(status)) {
          return true;
        } else if (report.report_type === 'HRV' && report.conclusions.hrv_test_analysis.includes(status)) {
          return true;
        } else if (report.report_type === 'REPORT_HYPERKALEMIA' && report.conclusions.arrhythmiaEvaluation.includes(status)) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (reportType && reportType !== 'All') {
      filtered = filtered.filter((report) => report.report_type === reportType);
    }

    if (searchTerm) {
      filtered = filtered.filter((user) => {
        return (
          user?.user_data?.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user?.user_data?.phone_number?.includes(searchTerm)
        );
      });
    }

    setFilteredData(filtered);
  }

  //to fetch all the reports based on time . by default the time is set to recent 
  const fetchReports = async () => {
    setLoading(true)
    let response;
    if (datePicker) {

      response = await getAllReports({
        business_id: id,
        startDate: formatDate(datePicker),
        endDate: formatDate(datePicker),

      });
    } else {
      response = await getAllReports({
        business_id: id,
        time: time,

      });
    }

   
    setLoading(false);
    setData(response.data)

  }

  const handleTime = async (e) => {
    setDatePicker(null);
    setTime(e);
  }

  const handleStatus = async (e) => {
    const status = e.target.value
    setStatus(status)

  }

  const handleActionButton = async (data) => {
    setSelectedData(data);
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const getReportStatus = (d) => {
    if (d.report_type == 'REPORT_TWELVE_LEAD' || d.report_type == 'REPORT_LEAD_TWO' || d.report_type == 'REPORT_SEVEN_LEAD') {
      return d.conclusions.ecg_type

    }
    else if (d.report_type === 'HRV') {
      return d.conclusions.hrv_test_analysis
    }
    else if (d.report_type === 'REPORT_HYPERKALEMIA') {
      return d.conclusions.arrhythmiaEvaluation
    }
  }

  const handleDownloadButton = async (path, reportType) => {

    try {
      const response = await downloadLeadFile({
        path: [path],
        report_type: reportType
      });

      response.urls.forEach(url => {

        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    }
    catch (e) {
      console.log(e)
    }

  }

  const handleDownloadAll = async () => {
    if (!selectedData || !selectedData.paths) return;

    const paths = Object.keys(selectedData.paths).map(key => selectedData.paths[key]);
    const response = await downloadLeadFile({
      path: paths,
      report_type: selectedData?.report_type
    });
   
    response.urls.forEach(url => {

      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    })
  };

  const formatDate = (date) => {

    return dayjs(date).format('DD MMM,YYYY');

  }

  const handleReportType = (e) => {

    setReportType(e.target.value)
  }

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const handleDateChange = (date) => {
    
    setTime(null)
    setDatePicker(date)

  }

 
  return (
    <>
      <Container sx={{ marginTop: isLg ? 0 : 1 }}>

        <Stack direction="column" mb={2}>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>

            <ButtonGroup variant="outlined" size="large" aria-label="Large button group">
              <Button
                color="primary"
                variant={time === 'Recent' ? "contained" : "outlined"}
                onClick={() => handleTime('Recent')}
              >
                Recent
              </Button>
              <Button
                color="success"
                variant={time === 'Weekly' ? "contained" : "outlined"}
                onClick={() => handleTime('Weekly')}
              >
                Weekly
              </Button>
              <Button
                color="warning"
                variant={time === 'Monthly' ? "contained" : "outlined"}
                onClick={() => handleTime('Monthly')}
              >
                Monthly
              </Button>
            </ButtonGroup>

          <Stack direction="row" alignItems="center" spacing={2} >
            <FormControl sx={{ m: 2, minWidth: 200 }} size="medium">
              <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                 variant="standard"
                value={reportType}
                label="report type"
                onChange={handleReportType}
              >
                <MenuItem value={'REPORT_TWELVE_LEAD'}>Twelve Lead Report</MenuItem>
                <MenuItem value={'REPORT_LEAD_TWO'}>Lead Two Report</MenuItem>
                <MenuItem value={'REPORT_SEVEN_LEAD'}>Seven Lead Report</MenuItem>
                <MenuItem value={'HRV'}>HRV</MenuItem>
                <MenuItem value={'REPORT_HYPERKALEMIA'}>Hyperkalemia Report</MenuItem>
                <MenuItem value={'All'}>All Reports</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ m: 2, minWidth: 200 }} size="medium">
              <InputLabel id="demo-simple-select-label">Report Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                variant="standard"
                label="report status"
                onChange={handleStatus}
              >
                <MenuItem value={'Normal'}>Normal ECG</MenuItem>
                <MenuItem value={'Abnormal'}>Abnormal ECG</MenuItem>
                <MenuItem value={'Critical'}>Critical</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
              </Select>
            </FormControl>

            <DatePicker
              sx={{ width: '160px' }}
              label="Select Date"
              value={datePicker}
              onChange={handleDateChange}
            />
          </Stack>
          
        </Stack>

          <Stack >
            <TextField
              id="search-bar"
              label="Search by name or number"
              variant="standard"
              value={searchTerm}
              onChange={handleSearch}
              size="small"
              sx={{ width: '250px' }}
            />
          </Stack>


        </Stack>

        <Box>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Phone Number</TableCell>
                    <TableCell align="right">Report Type</TableCell>
                    <TableCell align="right">Taken At</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                {filteredData.length > 0 ? (<TableBody>
                  {filteredData.map((user) => (
                    <TableRow
                      key={user?.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {user?.user_data?.first_name}
                      </TableCell>
                      <TableCell align="right">{user?.phone_number}</TableCell>
                      <TableCell align="right">{user?.report_type}</TableCell>
                      <TableCell align="right">{formatDate(user?.report_timestamp)}</TableCell>


                      <TableCell align="right">{getReportStatus(user)}</TableCell>

                      <TableCell align="right">
                        <Button onClick={() => { handleActionButton(user) }}>
                          action
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>) :
                  (<TableFooter>
                    <TableRow>
                      <TableCell style={{ width: '100%' }} align="center" colSpan={6}>
                        <Typography mt={2}>No Reports Found.</Typography>
                      </TableCell>
                    </TableRow>
                  </TableFooter>)}

              </Table>
            </TableContainer>
          )

          }

        </Box>

      </Container>
      <Dialog open={openDialong} onClose={handleCloseDialog} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { height: '50vh' } }}>
        <DialogTitle>Download Lead Files</DialogTitle>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DownloadForOfflineIcon />}
          onClick={handleDownloadAll}
          sx={{ marginLeft: 2, marginRight: 2, padding: '8px 16px', fontSize: '14px' }}
        >
          Download All
        </Button>
        <DialogContent>
          {selectedData && (

            <Box sx={{ width: '100%', padding: 2 }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {Object.keys(leads).map((key) => {
                  const leadLabel = leads[key];
                  const leadData = selectedData?.paths?.[key];
                  if (!leadData) {
                    return null;
                  }

                  return (
                    <Grid item xs={6} key={key}>
                      <Item>
                        <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <strong>{leadLabel}</strong>
                          <Button
                            variant="contained"
                            color="primary"
                            target="_blank"
                            startIcon={<DownloadForOfflineIcon />}
                            download
                            sx={{ marginLeft: 2 }}
                            onClick={() => handleDownloadButton(leadData, selectedData?.report_type)}
                          >
                            Download
                          </Button>
                        </Typography>
                      </Item>
                    </Grid>


                  );
                })}
              </Grid>
            </Box>
          )

          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>

  )
}


