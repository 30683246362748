import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Divider, FormHelperText, IconButton, Card, Box, Stack, FormGroup, FormControlLabel, Checkbox, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from '../components/snackbar';
import FormProvider from 'hooks/form/form-provider';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { saveB2BProfile } from 'api/users';
import { useAuthDispatch } from '../components/AuthStateProvider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard, downloadFromURI } from 'utils/Utils';



function B2BProfile({ data, isAdmin, onSubmitDone }) {
    const { enqueueSnackbar } = useSnackbar();
    const { updateLocalStorage } = useAuthDispatch();
    const indianStates = [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
        'Andaman and Nicobar Islands',
        'Chandigarh',
        'Dadra and Nagar Haveli',
        'Daman and Diu',
        'Delhi',
        'Lakshadweep',
        'Puducherry',
    ];

    const defaultValues = data ? {
        ...{ ...data, ...data?.company_data },
        TWELVE_LEAD: data.tests_enabled?.split(',').includes('TWELVE_LEAD'),
        LEAD_TWO: data.tests_enabled?.split(',').includes('LEAD_TWO'),
        SPANDAN_LEGACY: data?.devices_enabled?.split(',').includes('SPANDAN_LEGACY'),
        SPANDAN_NEO: data?.devices_enabled?.split(',').includes('SPANDAN_NEO'),
        SPANDAN_PRO: data?.devices_enabled?.split(',').includes('SPANDAN_PRO'),
        is_active: data?.is_active,
        is_offline_sdk_mode: data?.is_offline_sdk_mode,
        verifier_token: data?.verifier_token,
        usage_limit: data?.usage_limit,
        settings: data?.settings || {
            can_view_data: true,
            can_download_report: true,
            can_request_for_review: true
        }
    } : {
        phone_number: '',
        name: '',
        email: '',
        business_name: '',
        gst_number: '',
        billing_address: '',
        state: '',
        pin: '',
        code: '',
        settings: {
            can_view_data: true,
            can_download_report: true,
            can_request_for_review: true
        }
    };
    const onCopyClick = (text) => (e) => {
        copyToClipboard(text);
        enqueueSnackbar("Copied to clipboard.", { variant: 'success' });
    };


    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting },
    } = methods;

    const [showAdditionalFields, setShowAdditionalFields] = useState(false);

    const additionalFields = () => {
        setShowAdditionalFields(!showAdditionalFields);
    };
    const handleTestLimitChange = (event) => {
        methods.setValue('test_charges', event.target.value);
    };

    const onSubmit = handleSubmit(async (formData) => {

        try {

            if (!isAdmin) delete formData?.interpretation_charges;
            if (!data?.phone_number) formData.phone_number = `+91${formData?.phone_number}`;
            let enabledDevices = Object.keys(formData)
                .filter(key => formData[key] && ['SPANDAN_PRO', 'SPANDAN_NEO', 'SPANDAN_LEGACY'].includes(key))
                .join(',');
            let testsEnabled = Object.keys(formData)
                .filter(key => formData[key] && ['TWELVE_LEAD', 'LEAD_TWO'].includes(key))
                .join(',');

            formData.updated_devices_enabled = enabledDevices;
            formData.updated_tests_enabled = testsEnabled;
            const response = await saveB2BProfile(formData)
            if (response.success === true && response.status == 200) {
                if (!isAdmin) {
                    // const updatedValues={...data, name: formData?.name, displayName: formData?.name, email: formData?.email, company_data: response?.data?.company_data }
                    // localStorage.setItem('TokenDetails',JSON.stringify(updatedValues))



                    const updatedValues = {
                        updated_devices_enabled: formData?.enableddevices, updated_tests_enabled: formData?.testsEnabled
                        , name: formData?.name, displayName: formData?.name, email: formData?.email, company_data: response?.data?.company_data
                    };
                    updateLocalStorage('TokenDetails', updatedValues);
                }
                enqueueSnackbar(response.message, { variant: 'success' })
                if (onSubmitDone) onSubmitDone(true)
            } else if (response.success === false) {
                enqueueSnackbar("Unable to save the data.", { variant: 'error' })
                if (onSubmitDone) onSubmitDone(null, true)
            }
        } catch (e) {
            console.log(e)
            enqueueSnackbar("Unable to save the data.", { variant: 'error' })
            if (onSubmitDone) onSubmitDone(null, true)
        }
    })

    return (
        <Box px={3} pb={3}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            name="phone_number"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    sx={{ minWidth: 70 }}
                                    label="Phone number"
                                    fullWidth
                                    disabled={data?.phone_number}
                                    placeholder='9999999999'
                                    {...register('phone_number', {
                                        required: "Please enter the phone number.",
                                        pattern: {
                                            value: !data?.phone_number ? /^[0-9]{10}$/i : '',
                                            message: 'Invalid phone number'
                                        }
                                    })}
                                    error={!!errors.phone_number}
                                    helperText={errors.phone_number?.message}
                                    InputProps={!data?.phone_number ? {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                +91
                                            </InputAdornment>
                                        ),
                                    } : null}
                                />
                            )} />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    sx={{ minWidth: 70 }}
                                    label="User Name"
                                    fullWidth
                                    {...register('name', {
                                        required: "Please enter business owner's name."
                                    })}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            )} />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    sx={{ minWidth: 70 }}
                                    label="Email"
                                    fullWidth
                                    {...register('email', {
                                        required: 'Enter the email id',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                />
                            )} />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            name="business_name"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    sx={{ minWidth: 70 }}
                                    label="Business/Company Name"
                                    fullWidth
                                    {...register('business_name', {
                                        required: 'Please enter the business name.'
                                    })}
                                    error={!!errors.business_name}
                                    helperText={errors.business_name?.message}
                                />
                            )} />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            name="gst_number"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    value={(field?.value || '')?.toUpperCase()}
                                    sx={{ minWidth: 100 }}
                                    label="GST Number"
                                    fullWidth
                                />
                            )} />
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            name="billing_address"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    sx={{ minWidth: 70 }}
                                    label="Billing Address"
                                    fullWidth
                                    {...register('billing_address', {
                                        required: 'Please enter the billing address.'
                                    })}
                                    error={!!errors.billing_address}
                                    helperText={errors.billing_address?.message}
                                />
                            )} />
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            name="state"
                            control={control}
                            defaultValue={defaultValues?.state}
                            rules={{
                                required: 'Please select the state.'
                            }}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    sx={{ minWidth: 200 }}
                                    options={indianStates}
                                    {...register('state')}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            label="Select State"
                                            error={!!errors.state}
                                            helperText={errors.state?.message} />
                                    )}
                                    onChange={(event, value) => {
                                        field.onChange(value);
                                    }}
                                />
                            )} />
                    </Grid>
                    <Grid item md={4} sm={3} xs={6}>
                        <Controller
                            name="pin"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    sx={{ minWidth: 100 }}
                                    label="Pin code"
                                    fullWidth
                                    {...register('pin', {
                                        required: 'Please enter the pin code.',
                                        pattern: {
                                            value: /^[0-9]{6,6}$/i,
                                            message: 'Invalid pin code'
                                        }
                                    })}
                                    error={!!errors.pin}
                                    helperText={errors.pin?.message}
                                />
                            )} />
                    </Grid>
                    <Grid item md={4} sm={3} xs={6}>
                        <Controller
                            name="code"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    sx={{ minWidth: 100 }}
                                    label="GST State code"
                                    fullWidth
                                    {...register('code', {
                                        required: 'Please enter the state code.',
                                        pattern: {
                                            value: /^[0-9]{1,2}$/i,
                                            message: 'Invalid state code'
                                        }
                                    })}
                                    error={!!errors.code}
                                    helperText={errors.code?.message}
                                />
                            )} />
                    </Grid>
                </Grid>
                { isAdmin && (
                <>
                    <Box py={2}>
                        <Button onClick={additionalFields}>
                            {showAdditionalFields ? (<RemoveIcon sx={{ mr: 1 }} />) : (<AddIcon sx={{ mr: 1 }} />)}Additional details
                        </Button>
                    </Box>
                    { showAdditionalFields && (
                        <Grid container spacing={1} justifyContent='center'>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                                <FormControl>
                                    <TextField
                                        defaultValue={data?.test_charges}
                                        sx={{ minWidth: 70 }}
                                        onChange={handleTestLimitChange}
                                        label="Test Charges (per test)"
                                        size='small'
                                        error={!!errors.test_charges}
                                        helperText={errors.api_key?.test_charges}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        sx={{ minWidth: 70 }}
                                        label="Interpretation charges (per request)"
                                        size='small'
                                        {...register('interpretation_charges', {
                                            pattern: {
                                                value: /^[0-9]+$/i,
                                                message: 'Invalid interpretation charge'
                                            }
                                        })}
                                        error={!!errors.interpretation_charges}
                                        helperText={errors.interpretation_charges?.message}
                                    />
                                </FormControl>
                                <FormGroup item md={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                                    <FormControl item md={12} sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                        <TextField
                                            defaultValue={data?.api_key}
                                            sx={{ minWidth: 70 }}
                                            label="business id/api key"
                                            size='small'
                                            disabled
                                            InputProps={{
                                                endAdornment: (data?.api_key ?
                                                    <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                                                        <ContentCopyIcon fontSize='small' sx={{ cursor: "pointer" }} onClick={onCopyClick(data?.api_key)} />
                                                    </InputAdornment> : null
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormControl item md={12} sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    <TextField
                                        defaultValue={data?.verifier_token}
                                        sx={{ minWidth: 70 }}
                                        label="Verify Token"
                                        size='small'
                                        disabled
                                        InputProps={{
                                            endAdornment: (data?.verifier_token ?
                                                <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                                                    <ContentCopyIcon fontSize='small' sx={{ cursor: "pointer" }} onClick={onCopyClick(data?.verifier_token)} />
                                                </InputAdornment> : null
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.8rem', md: '0.9rem' } }}>Settings: </Typography>
                                <FormGroup item md={12} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, pb: 1 }}>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked={defaultValues?.settings?.can_view_data} {...methods.register('settings.can_view_data')} />}
                                        label="Can view tests"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked={defaultValues?.settings?.can_request_for_review} {...methods.register('settings.can_request_for_review')} />}
                                        label="Can request for review"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked={defaultValues?.settings?.can_download_report} {...methods.register('settings.can_download_report')} />}
                                        label="Can download report"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.8rem', md: '0.9rem' } }}>Enabled Devices</Typography>
                                <FormGroup item md={12} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked={data?.devices_enabled?.split(',').includes('SPANDAN_LEGACY')} {...methods.register('SPANDAN_LEGACY')} />}
                                        label="Spandan Lagecy"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked={data?.devices_enabled?.split(',').includes('SPANDAN_NEO')} {...methods.register('SPANDAN_NEO')} />}
                                        label="Spandan Neo"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked={data?.devices_enabled?.split(',').includes('SPANDAN_PRO')} {...methods.register('SPANDAN_PRO')} />}
                                        label="Spandan Pro"
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                <FormGroup item md={12} sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    <Grid item>
                                        <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.8rem', md: '0.9rem' } }}>Status</Typography>
                                        <FormControlLabel
                                            control={<Checkbox defaultChecked={data?.is_active} onChange={(e) => methods.setValue('is_active', e.target.checked)} />}
                                            label={'Active'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.8rem', md: '0.9rem' } }}>Offline SDK mode</Typography>
                                        <FormControlLabel
                                            control={<Checkbox defaultChecked={data?.is_offline_sdk_mode} onChange={(e) => methods.setValue('is_offline_sdk_mode', e.target.checked)} />}
                                            label={'Enabled'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.8rem', md: '0.9rem' } }}>Uses Limit</Typography>
                                        <TextField
                                            defaultValue={data?.usage_limit}
                                            size='small'
                                            placeholder='Usage limit'
                                            sx={{ height: 36, width: '40' }}
                                            {...register('usage_limit')}
                                        />
                                    </Grid>
                                    <Grid >
                                        <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.8rem', md: '0.9rem' } }}>Enabled Tests</Typography>
                                        <FormGroup item md={12} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    defaultChecked={data?.tests_enabled?.split(',').includes('TWELVE_LEAD')}
                                                    onChange={(e) => setValue('TWELVE_LEAD', e.target.checked)}
                                                />}
                                                label="Twelve Lead"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox
                                                    defaultChecked={data?.tests_enabled?.split(',').includes('LEAD_TWO')}
                                                    onChange={(e) => setValue('LEAD_TWO', e.target.checked)}
                                                />}
                                                label="Two Lead"
                                            />
                                        </FormGroup>

                                    </Grid>

                                </FormGroup>
                            </Grid>
                        </Grid>
                    )}
                </>)}
                <Grid item xs={12} textAlign='center'>
                    <Divider sx={{ my: 2 }} />
                    <LoadingButton
                        sx={{ minWidth: 150 }}
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Save
                    </LoadingButton>
                </Grid>
            </FormProvider>
        </Box>
    )
}

export default B2BProfile;