import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip'
import React from 'react';

// @mui

import ClearIcon from '@mui/icons-material/Clear';
import { styled, alpha } from '@mui/material/styles';
import { Grid, Toolbar, Button, Box, Tooltip, Typography, OutlinedInput, InputAdornment, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
// component
import Iconify from '../../../components/iconify';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// ----------------------------------------------------------------------
const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 86,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 0),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 300,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const AdminListToolbar = ({ B2BUserdata, numSelected, selectedIds, unselecteReports, handleSearchAction }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [BtBuser, setBtBuser] = useState('');

  const handleChange = (event) => {
    setBtBuser(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearch = () => {
    const searchData = {
      startDate: startDate,
      endDate: endDate,
      business_id: BtBuser,
    };
    handleSearchAction(searchData);
  };

  const clearSelected = () => {
    unselecteReports(null, selectedIds);
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Tooltip title="Clear">
          <ClearIcon onClick={clearSelected} />
        </Tooltip>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
          <Box sx={{ minWidth: 120, marginRight: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">B2B Users</InputLabel>
              <Select
                value={BtBuser}
                label="B2B Users"
                onChange={handleChange}
              >
                {B2BUserdata && B2BUserdata.data && B2BUserdata.data.length > 0 && B2BUserdata.data.map((user) => (
                  <MenuItem key={user.business_id} value={user.business_id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, marginRight: 1 }}>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </Grid>

              <Grid item md={6}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Button variant="contained" onClick={handleSearch} startIcon={<SearchIcon />}>
            Search
          </Button>
        </Box>
      )}
    </StyledRoot>
  );
};

export default AdminListToolbar;
