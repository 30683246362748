import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import FormProvider from 'hooks/form/form-provider';
import { useForm, Controller } from 'react-hook-form'; // Importing useForm hook
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Box,
    Grid,
    Checkbox,
    FormGroup,
    FormControl,
    Button,
    Card,
    Container,
    Dialog,
    FormControlLabel,
    DialogActions,
    DialogTitle,
    Divider,
    InputAdornment,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { styled } from '@mui/system';

// Additional components
import { useSnackbar } from '../components/snackbar';
import Scrollbar from '../components/scrollbar';
import { useAuthDispatch } from 'components/AuthStateProvider';

// API functions
import { addB2BAdmin, removeB2BAdminUser, getB2Badmindata } from 'api/users';
import { copyToClipboard, downloadFromURI } from 'utils/Utils';
import useResponsive from '../hooks/useResponsive';
import { useTheme } from '@mui/material/styles';

const OverflowTypography = styled(Typography)(() => ({
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
}));

export default function Managebusinessuser() {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const isSmall = useResponsive('', 'xs');
    const isLg = useResponsive('up', 'md');
    const { account } = useAuthDispatch();

    const [openAddUser, setOpenAddUser] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [action, setAction] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await getB2Badmindata();
            setLoading(false);
            if (response?.success) {
                setData(response?.data || []);
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } catch (e) {
            setLoading(false);
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    };

    const onDeleteDataClick = async () => {
        try {
            if (selectedData) {
                setIsSubmitting(true);
                const response = await removeB2BAdminUser({ phone_numbers: [selectedData?.phone_number] });
                if (response?.success) {
                    fetchData();
                } else {
                    enqueueSnackbar(response.message, { variant: 'error' });
                }
                handleClosePopup();
            } else {
                enqueueSnackbar("Please select user to delete.", { variant: 'error' });
            }
        } catch (e) {
            enqueueSnackbar(e.message || "Unable to delete the user.", { variant: 'error' });
            console.log(e);
        } finally {
            handleClosePopup();
        }
    };

    const handleEditClick = (row) => (e) => {
        setSelectedData(row);
        setOpenAddUser(true);
    }

    const handleDeleteClick = (row) => (e) => {
        setSelectedData(row);
        setAction("delete");
    }

    const handleAddUser = (formData) => {
        if (formData) fetchData();
        handleClosePopup();
    };
    
    const handleClosePopup = (e) => {
        setOpenAddUser(false);
        setSelectedData(null);
        setAction(null);
        setIsSubmitting(false);
    }
    
    

    return (
        <>
            <Helmet>
                <title> Manage Your User | Sunfox Experts</title>
            </Helmet>

            <Container sx={{ marginTop: (isLg ? 0 : 1) }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Manage business system users
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" onClick={() => setOpenAddUser(true)}>
                            <Add sx={{ mr: 1 }} />
                            Add User
                        </Button>
                    </Stack>
                </Stack>
                <Card>
                    <Box>
                        <Box>
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Scrollbar>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ minWidth: 150 }}>Name</TableCell>
                                                <TableCell sx={{ minWidth: 100 }}>Phone No.</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {data.length ? (
                                            <TableBody>
                                                {data.map((row, index) => (
                                                    <TableRow hover key={index} tabIndex={-1}>
                                                        <TableCell>
                                                            <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                                                {row.name}
                                                            </OverflowTypography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                                                {row.phone_number}
                                                            </OverflowTypography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Stack direction="row" spacing={2}>
                                                                <Tooltip title="Edit" arrow>
                                                                    <LoadingButton
                                                                        onClick={ handleEditClick(row) }
                                                                        loading={false}
                                                                        variant="contained"
                                                                        disabled={selectedData?.phone_number === row?.phone_number}
                                                                    >
                                                                        Edit
                                                                    </LoadingButton>
                                                                </Tooltip>
                                                                {row?.phone_number !== account?.phoneNumber ?
                                                                <Tooltip title="Delete" arrow>
                                                                    <LoadingButton
                                                                        onClick={ handleDeleteClick(row) }
                                                                        loading={false}
                                                                        variant="contained"
                                                                        color='error'
                                                                        disabled={selectedData?.phone_number === row?.phone_number}
                                                                    >
                                                                        Delete
                                                                    </LoadingButton>
                                                                </Tooltip> : null }
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell style={{ width: '100%' }} align="center" colSpan={3}>
                                                        <Typography mt={2}>No user found.</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                    </Table>
                                </Scrollbar>
                            )}
                        </Box>
                    </Box>
                </Card>
            </Container>
            {openAddUser && <Dialog
                fullWidth
                maxWidth="sm"
                scroll='body'
                disableBackdropClick={true}
                open={openAddUser}
                onClose={handleClosePopup}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6">{ selectedData ? "Update" : "Add"} B2B system user</Typography>
                    <Button onClick={handleClosePopup}>Close</Button>
                </DialogTitle>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                    <EditUserCompnent selectedData={ selectedData } onFormSubmit={ handleAddUser } />
                </Box>
            </Dialog> }
            {(action === "delete" && selectedData) && <Dialog
                fullWidth
                maxWidth="xs"
                scroll='body'
                disableEscapeKeyDown={handleClosePopup}
                open={selectedData ? true : false}
                onClose={handleClosePopup}
                transitionDuration={{
                    enter: theme.transitions.duration.shortest,
                    exit: theme.transitions.duration.shortest - 80,
                }}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    Delete confirmation
                </DialogTitle>
                <Box sx={{ px: 3, pb: 4 }}>Do you want to delete system user <b>{selectedData?.name}</b>?</Box>
                <Divider />
                <DialogActions>
                    <Stack direction='row' spacing={4} sx={{ px: 2, py: 1 }}>
                        <Button variant="outlined" color="error" onClick={handleClosePopup}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            onClick={onDeleteDataClick}
                        >
                            Confirm
                        </LoadingButton>
                    </Stack>
                </DialogActions>
            </Dialog>}
        </>
    );
}

function EditUserCompnent({ selectedData, onFormSubmit }) {
    const { enqueueSnackbar } = useSnackbar();
    const defaultValues = {
        phone_number: selectedData?.phone_number,
        name: selectedData?.name,
        email: selectedData?.email,
        settings: selectedData?.settings || {
            can_view_data: true,
            can_download_report: true,
            can_request_for_review: true,
            can_manage_business_admin: true,
            can_manage_associated_user: true
        }
    };

    let methods = useForm({
        defaultValues,
        mode: "onChange",
    });

    const { control, register, handleSubmit, formState: { errors, isSubmitting } } = methods;

    const onSubmit = handleSubmit(async (formData) => {
        try {
            let { name, email, phone_number, settings } = formData;
            if (!selectedData?.phone_number) phone_number = `+91${phone_number}`;
            const response = await addB2BAdmin({ phone_numbers: [phone_number], name, email, settings });
            if (response.success) {
                enqueueSnackbar(response.message || 'Added successfully.', { variant: 'success' });
                onFormSubmit(response)
            } else {
                enqueueSnackbar(response.message || 'Unable to add user.', { variant: 'error' });
                onFormSubmit()
            }
            
        } catch (error) {
            enqueueSnackbar('Failed to add user.', { variant: 'error' });
            console.error(error);
        } finally {
            onFormSubmit()
        }
    })

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="phone_number"
                            control={control}
                            defaultValue={ defaultValues?.phone_number }
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Phone number"
                                    fullWidth
                                    margin='normal'
                                    error={!!errors.phone_number}
                                    helperText={errors.phone_number?.message}
                                    InputProps={ {
                                        startAdornment: !selectedData?.phone_number ? (
                                            <InputAdornment position="start">
                                                +91
                                            </InputAdornment>
                                        ): null,
                                    }}
                                    {...register('phone_number', {
                                        required: "Please enter the phone number.",
                                        pattern: {
                                            value: !selectedData?.phone_number ? /^[0-9]{10}$/i : /^\+[0-9]{12}$/i,
                                            message: 'Invalid phone number'
                                        }
                                    })}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={ defaultValues?.name }
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Name"
                                    fullWidth
                                    margin='normal'
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                    {...register('name', {
                                        required: "Please enter the name."
                                    })}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={ defaultValues?.email }
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Email"
                                    fullWidth
                                    margin='normal'
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    {...register('email', {
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.8rem', md: '0.9rem' } }}>Settings: </Typography>
                    <FormGroup fullWidth item md={12} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, pb: 1 }}>
                        <FormControlLabel
                            control={<Checkbox defaultChecked={defaultValues?.settings?.can_view_data} {...register('settings.can_view_data')} />}
                            label="Can view test data"
                        />
                        <FormControlLabel
                            control={<Checkbox defaultChecked={defaultValues?.settings?.can_request_for_review} {...register('settings.can_request_for_review')} />}
                            label="Can request for review"
                        />
                        <FormControlLabel
                            control={<Checkbox defaultChecked={defaultValues?.settings?.can_download_report} {...register('settings.can_download_report')} />}
                            label="Can download report"
                        />
                        <FormControlLabel
                            control={<Checkbox defaultChecked={defaultValues?.settings?.can_manage_business_admin} {...register('settings.can_manage_business_admin')} />}
                            label="Can manage system users"
                        />
                        <FormControlLabel
                            control={<Checkbox defaultChecked={defaultValues?.settings?.can_manage_associated_user} {...register('settings.can_manage_associated_user')} />}
                            label="Can manage associated users"
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} textAlign='center'>
                    <Divider sx={{ my: 2 }} />
                    <LoadingButton
                        type="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        sx={{
                            alignSelf: 'flex-end',
                            color: '#ffffff',
                            bgcolor: '#1976d2',
                            '&:hover': {
                                bgcolor: '#1565c0',
                            },
                            mt: 2
                        }}
                        variant='primary'>Save</LoadingButton>
                </Grid>
            </Grid>
        </FormProvider>
    );
}
