import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { Download, Share, ContentCopy, TaskAltOutlined, ScheduleOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from 'react-router-dom';
import Label from 'components/label/Label';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getAllReports, revokeInterpretationRequest, getEcgData, getEcgReportAndData } from 'api/reports';
import { copyToClipboard, downloadFromURI } from 'utils/Utils';

import LoadingButton from '@mui/lab/LoadingButton';
import { List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, ButtonGroup, DialogActions, Box, Chip, Link, FormControl, InputLabel, Select, MenuItem, Alert, Card, Grid, Avatar, Table, Stack, Container, Typography, TableContainer, TablePagination, Tooltip, Button, TableRow, TableCell, TableHead, TableBody, TableFooter, Snackbar } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { refreshSocket } from '../utils/socket'
import { useSnackbar } from '../components/snackbar'
import { darken, lighten, styled } from '@mui/material/styles';
import { useAuthDispatch } from 'components/AuthStateProvider';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';


//------------------------------------Data Grid-------------------------//

import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';
//import { DataGrid, GridToolbar } from '@mui/x-data-grid';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)((maxWidth) => ({
  maxWidth: `${maxWidth}px`, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function AllReports() {
  const documentRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useResponsive('', 'xs');
  const isMd = useResponsive('between', 'xs', 'md');
  const isLg = useResponsive('up', 'md');
  const { account } = useAuthDispatch();
  const { searchStatus, searchStDate, searchEnDate, searchResult } = JSON.parse(window.sessionStorage.getItem("searchDetails") || '{}');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [dataCount, setDataCount] = useState(null);
  const [status, setstatus] = useState(searchStatus || 'requested');
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(searchStDate ? dayjs(searchStDate) : dayjs());
  const [endDate, setEndDate] = useState(searchEnDate ? dayjs(searchEnDate) : dayjs());
  const [interpretationStatusFilter, setInterpretationStatusFilter] = useState('all');
  const [rowLoading, setRowLoading] = useState(null);
  const [socket, setSocket] = useState();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [selectionModel, setSelectionModel] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [pdf, setPdf] = useState(null);
  const [pdfScale, setPdfScale] = useState(isLg || isMd ? 1 : 0.5);
  const [openViewPdfDialog, setOpenViewPdfDialog] = useState(false);
  const [rowId, setRowId] = useState(null)
  const [openLink, setopenLink] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [pdfData, setPdfData] = useState();


  const handleZoom = (scale) => {
    setPdfScale(scale <= 1 ? (isLg ? 1 : 0.5) : scale)
  };
  const dialogStyles = {
    overflow: 'hidden', // Remove scroll bar

    padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'
  };

  async function getSocketDetails() {
    setSocket(await refreshSocket());
  }

  const navigate = useNavigate();

  const TABLE_HEAD = useMemo(() => [
    { field: 'searchname', headerName: 'Name', width: status === 'completed' ? 180 : 250, align: 'left', renderCell: (params) => params.row.name, filterable: true },
    { field: 'searchreportType', headerName: 'Report Type', width: status === 'completed' ? 120 : 150, align: 'left', renderCell: (params) => params.row.reportType, filterable: true },
    {
      field: 'searchtakenAt', headerName: 'Taken At', width: status === 'completed' ? 120 : 130, align: 'left', renderCell: (params) => params.row?.takenAt, filterable: true, sortComparator: (v1, v2, param1, param2) => {
        return new Date(v1) - new Date(v2);
      },
    },
    {
      field: 'searchrequestedAt', headerName: 'Requested At', width: status === 'completed' ? 130 : 150, align: 'left', renderCell: (params) => params.row?.requestedAt, filterable: true, sortComparator: (v1, v2, param1, param2) => {
        return new Date(v1) - new Date(v2);
      },
    },
    ...(status === 'completed' || status === '' ? [{
      field: 'searchcompletedAt', headerName: 'Completed At', width: 130, align: 'left', renderCell: (params) => params.row?.completedAt, filterable: true, sortComparator: (v1, v2, param1, param2) => {
        return new Date(v1) - new Date(v2);
      },
    }] : []),
    ...(status === 'completed' || status === '' ? [{ field: 'searchfindings', headerName: 'Findings', width: 100, align: 'left', renderCell: (params) => params.row.findings_status }] : []),
    { field: 'searchecg_status', headerName: 'Status', width: status === 'completed' ? 160 : 200, align: 'left', renderCell: (params) => params.row.ecg_status },
    { field: 'action', headerName: 'Action', width: status === 'completed' ? 180 : 200, align: 'left', renderCell: (params) => params.row.action },
  ]);
  const styles = {
    documentBlock: {
      // maxWidth: 800,
      margin: "0px",
      marginTop: 5,
      // border: "1px solid #999",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      height: "90vh",
      overflow: "auto",
    },
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0)
        return order;
      return a[1] - b[1];
    });
    if (query) {
      return array.filter((_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const formatDate = (date) => {
    return date ? dayjs(date).format('DD MMM, YYYY') : null;
  };

  const handleStartDateChange = (date) => {

    setStartDate(date);
    setLoading(true);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setLoading(true);
  };

  const metaData = selectedRowData?.meta_data ? JSON.parse(selectedRowData.meta_data) : {};


  useEffect(() => {
    if (socket) {
      socket.on('addReport', (result) => {
        if (result && result?.length) {
          const filteredData = result.filter(item => !data.some(dt => item?.id === dt?.id))
          setData(prevData => [...filteredData.map(item => { return { ...item, is_new: true } }), ...prevData])
        }

      })

      socket.on('InterpretationInProgress', (resultData) => {

        if (resultData) setData((prevItems) =>
          prevItems.map((item) =>
            item.id === resultData?.id ? { ...item, ...resultData, selected: true } : item
          )
        );

      })
    } else {
      getSocketDetails();
    }
  }, [socket])


  useEffect(() => {
    fetchData();
  }, [status, startDate, endDate]);

  const fetchData = async () => {
    try {

      const response = await getAllReports({
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        status: status
      });


      setLoading(false);
      if (response && response?.success) {
        const filteredData = response?.data?.filter(row =>
          !row.interpretation_request_status
          || row.interpretation_request_status === "requested"
          || row.interpretation_request_status === "in progress"
          || row.interpretation_request_status === "completed"
        );

        const mappedData = filteredData?.map(row => {
          return {
            ...row,
            interpretation_data_logs: row?.interpretation_completed_at ? [{ interpreter_details: row?.interpreter_details, completed_at: parseInt(row?.interpretation_completed_at) }, ...(row?.interpretation_data_logs || [])] : []
          }
        });
        setData(mappedData || []);
        setDataCount(response?.counts);
        sortingData(mappedData || []);
        const searchDetails = {
          searchStatus: status,
          searchStDate: startDate,
          searchEnDate: endDate,
          searchResult: mappedData
        };
        window.sessionStorage.setItem("searchDetails", JSON.stringify(searchDetails));
      } else {
        if (response) {
          enqueueSnackbar(response.message, { variant: 'error' })
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  function sortingData(reportData) {
    //--------------------------------------------//
    let filteredUsers;
    if (status === "completed" || status === "") {
      filteredUsers = applySortFilter(
        reportData.filter(row => {
          if (interpretationStatusFilter === 'all') {
            return true;
          } else {
            return row?.interpretation_report_status === interpretationStatusFilter;
          }
        }),
        getComparator(order, orderBy),
        filterName
      );
    } else {
      filteredUsers = applySortFilter(reportData, getComparator(order, orderBy), filterName);
    }

    const dataRows = filteredUsers.map((row, index) => {
      return {
        id: `${row?.id}`,
        searchname: `${row?.user_data?.first_name} ${row?.user_data?.last_name}, ${row?.user_data?.phone_number}`,
        searchreportType: `${row?.report_type?.replace(/REPORT|_/g, ' ')}`,
        searchfindings: row?.interpretation_report_status,
        searchecg_status: row?.interpretation_request_status,
        searchtakenAt: row?.report_timestamp ? parseInt(row?.report_timestamp) : '',
        searchrequestedAt: row?.interpretation_requested_at ? parseInt(row?.interpretation_requested_at) : '',
        searchcompletedAt: row?.interpretation_completed_at ? parseInt(row?.interpretation_completed_at) : '',
        name: <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2} >
            <ContentCopy fontSize='small' sx={{ cursor: "pointer" }} onClick={onCopyClick(row?.user_data?.phone_number)} />

            <Tooltip title={row?.user_data?.phone_number}>

              <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                {row?.user_data?.first_name} {row?.user_data?.last_name}
              </OverflowTypography>
            </Tooltip>
          </Stack>
        </TableCell>,
        reportType:
          <TableCell component="th" scope="row" padding="none">
            <Stack direction="row" alignItems="center" spacing={2} >
              <Box sx={{ m: 1, position: 'relative' }}>
                <PictureAsPdfIcon fontSize='small' sx={{ cursor: "pointer" }} onClick={(e) => onlyViewPdf(e, row)} />
              </Box>


              {/* {loadingPdf ? (
        <CircularProgress size={20} sx={{
          color: green[500],
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}/>
      ) : (
        <PictureAsPdfIcon fontSize='small' sx={{ cursor: "pointer" }} onClick={(e)=>onlyViewPdf(e,row)} />)
        } */}
              <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                {row?.report_type?.replace(/REPORT|_/g, ' ')}
              </OverflowTypography>

            </Stack>
          </TableCell>
        ,
        takenAt: <Tooltip title={(row?.report_timestamp ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM, YYYY hh:mm A') : '')}>
          <span>{(row?.report_timestamp ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM hh:mm A') : '')}</span>
        </Tooltip>,
        requestedAt: <Tooltip title={(row?.interpretation_requested_at ? dayjs(parseInt(row?.interpretation_requested_at)).format('DD MMM, YYYY hh:mm A') : '--')}>
          <span>{(row?.interpretation_requested_at ? dayjs(parseInt(row?.interpretation_requested_at)).format('DD MMM hh:mm A') : '--')}</span>
        </Tooltip>,
        completedAt: <Tooltip title={(row?.interpretation_completed_at ? dayjs(parseInt(row?.interpretation_completed_at)).format('DD MMM, YYYY hh:mm A') : '--')}>
          <span>{(row?.interpretation_completed_at ? dayjs(parseInt(row?.interpretation_completed_at)).format('DD MMM hh:mm A') : '--')}</span>
        </Tooltip>,
        findings_status: status === "completed" || !status ? (
          <Typography
            color={
              row?.interpretation_report_status === 'normal'
                ? 'success.main'
                : row?.interpretation_report_status === 'abnormal'
                  ? 'warning.main'
                  : row?.interpretation_report_status === 'critical'
                    ? 'error.main'
                    : row?.interpretation_report_status === 'retake'
                      ? 'info.main'
                      : 'default'
            }
          >
            {row?.interpretation_report_status === 'normal'
              ? 'Normal'
              : row?.interpretation_report_status === 'abnormal'
                ? 'Abnormal'
                : row?.interpretation_report_status === 'critical'
                  ? 'Critical'
                  : row?.interpretation_report_status === 'retake'
                    ? 'Retake'
                    : ' '}
          </Typography>
        ) : null,
        ecg_status: row?.selected || row?.interpretation_request_status === "in progress" ?
          <Tooltip color='warning' title="In progress"><Label color="warning"><ScheduleOutlined sx={{ mr: 1 }} fontSize='small' color='warning' severity="warning" /> <OverflowTypography maxWidth={100} variant="subtitle2" noWrap>{row?.interpreter_details?.name ? `By: ${row?.interpreter_details?.name}` : 'In progress'}</OverflowTypography></Label></Tooltip> :
          (row?.interpretation_request_status === "completed"
            ? <Tooltip color='warning' title="Interpreted">
              <Label color="success"><TaskAltOutlined sx={{ mr: 1 }} fontSize='small' color="success" /> <OverflowTypography maxWidth={100} variant="subtitle2" noWrap>{`By: ${row?.interpreter_details?.name}`}</OverflowTypography></Label>
            </Tooltip>
            : <Tooltip color='warning' title="New request"><Label color='primary'>
              {row?.interpretation_request_status}
            </Label>
            </Tooltip>)
        ,
        action: row?.interpretation_request_status === "in progress" ? (
          row?.interpreter_phone_number === account?.phoneNumber ? (
            <>
              <Button
                loading={(row?.id === rowLoading).toString()}
                aria-describedby="adadsa"
                variant="contained"
                color='warning'
                onClick={handleRevokeRequest(row?.id)}
              >
                Revoke
              </Button>
              <Button
                sx={{ ml: 2 }}
                aria-describedby="adadsa"
                variant="contained"
                onClick={(event) => handleAction(event, row, 'delete')}
              >
                Edit
              </Button>
            </>
          ) : "--"
        ) : row?.interpretation_request_status === 'completed' && dayjs(row?.interpretation_completed_at).isBefore(dayjs().subtract(1, 'minute')) ? (
          <>
            <Tooltip title="Download" arrow>
              <LoadingButton
                variant="contained"
                onClick={(event) => downloadPDF(event, row, 'download')}
              >
                <Download />
              </LoadingButton>
            </Tooltip>
            <Button
              sx={{ ml: 2 }}
              aria-describedby="adadsa"
              variant="contained"
              onClick={(event) => handleAction(event, row, 'delete')}


            >
              Edit
            </Button>
          </>
        )
          : (
            <Button
              aria-describedby="adadsa"
              variant="contained"
              onClick={(event) => handleAction(event, row, 'delete')}
              disabled={row?.selected || !row?.user_data?.first_name}
            >
              {row?.interpretation_request_status === "completed" ? "Edit" : "Interpret"}
            </Button>
          ),
        report_status: row?.interpretation_request_status,
        phone_number: row?.interpreter_phone_number,
        user_data: row?.user_data,
        interpreter_details: row?.interpreter_details,
        selected: row?.selected,
        rowsData: row,
        isTrue: row?.is_new,
      };
    });
    setRows(dataRows)
  }

  //--------------------------------------------//

  const handleAction = async (e, row, type) => {
    e.stopPropagation();
    if (socket && row?.interpretation_request_status === "requested") {
      socket.emit('onClickInterpret', row?.id, account?.authorization)
    }
    navigate(`${process.env.REACT_APP_HOMEPAGE}custominterpretation/${row?.id}`);
  };

  const downloadPDF = async (e, row) => {
    e.stopPropagation();
    const id = row?.id;
    setRowLoading(id);
    try {
      const reportresponse = await getEcgData({ id });
      const reportdata = reportresponse?.data;

      const fileName = `${reportdata?.user_data?.first_name}-${reportdata?.user_data?.last_name}-${dayjs(reportdata?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${reportdata?.interpretation_data?.report_status ? `-${reportdata?.interpretation_data?.report_status}` : ''}`;
      downloadFromURI(`data:application/pdf;base64,${reportdata?.ReportPdf}`, fileName)
      setRowLoading(null);
      enqueueSnackbar("Pdf download suceessfully", { variant: 'success' })

    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' })
    }
  };


  const onlyViewPdf = async (e, row) => {
    try {
      e.stopPropagation();
      const id = row?.id
      setRowId(id);

      if (row?.interpretation_request_status == 'requested') {
        getEcgReportAndData({
          id
        }).then(reportData => {
          const fetchedPdfData = reportData?.data?.ReportPdf
          if (!fetchedPdfData) {
            setOpenViewPdfDialog(false)
            enqueueSnackbar("Some error occurred while fetching pdf", { variant: 'error' })
          } else {
            const URL = `data:application/pdf;base64,${fetchedPdfData}`;
            setPdfData(reportData?.data)
            setPdf(URL);
          }
        })
      } else {
        setopenLink(true)
        getEcgData({ id }).then(reportData => {
          const fetchedPdfData = reportData?.data?.ReportPdf
          if (!fetchedPdfData) {
            setOpenViewPdfDialog(false)
            enqueueSnackbar("Some error occurred while fetching pdf", { variant: 'error' })
          } else {
            const URL = `data:application/pdf;base64,${fetchedPdfData}`;
            setPdfData(reportData?.data)
            setPdf(URL);
          }

        })
      }
      setOpenViewPdfDialog(true)
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' })
    }
  }

  const handleStatus = (newStatus) => {
    if (status === newStatus) {
      setstatus('');
      setLoading(true);
    } else {
      setstatus(newStatus);
      setLoading(true);
    }
  };

  const onCopyClick = (text) => (e) => {
    e.stopPropagation();
    copyToClipboard(text);
    enqueueSnackbar("Copied to clipboard.", { variant: 'success' })
  };

  const handleChange = (event) => {
    setLoading(true);
    setInterpretationStatusFilter(event.target.value);
    setLoading(false);
  };

  const handleRevokeRequest = (id) => async (e) => {
    e.stopPropagation();
    setRowLoading(id);
    const response = await revokeInterpretationRequest({ ids: [id] })
    if (response?.success) {
      setRowLoading(null);
      setData([...data.filter(item => item?.id !== id)])
      enqueueSnackbar(response.message, { variant: 'success' })
    } else {
      enqueueSnackbar(response.message, { variant: 'error' })
      setRowLoading(null);
    }
  };

  const StyledDataGrid = styled(DataGrid)({
    '.super-app-theme--Open': {
      backgroundColor: '#FFEEBA',
    },
  });

  function NoRowsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No rows in DataGrid
        <pre>(rows=&#123;[]&#125;)</pre>
      </Stack>
    );
  }

  const handleCloseDialog = useCallback(() => {
    setSelectedRowData(null);
    setIsDialogOpen(false);

  });

  const handleOpenDialog = (rowId, event) => {
    event.preventDefault();
    const rowData = data?.find(item => item?.id == rowId);
    if (rowData?.interpretation_completed_at || rowData?.interpretation_requested_at) {
      setSelectedRowData(rowData);
      setIsDialogOpen(true);
    }
  };

  const handleSortModalChange = (model) => {
    setSortModel(model)
  }
  const handleCloseViewPdfDialog = () => {
    setOpenViewPdfDialog(false);
    setPdf(null);
    setopenLink(false)

  }

  const downloadAppPdf = async () => {

    try {

      const reportResponse = await getEcgReportAndData({
        id: rowId
      })

      if (!reportResponse || reportResponse.status === 400) {
        throw new Error('Failed to fetch report data');
      }
      const reportData = reportResponse?.data;
      const fetchedPdfData = reportData?.ReportPdf;

      const uri = `data:application/pdf;base64,${fetchedPdfData}`;
      const fileName = `ecg-report-${reportData?.user_data?.first_name}${reportData?.user_data?.last_name ? `-${reportData?.user_data?.last_name}` : ''}-${dayjs(reportData?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${reportData?.interpretation_data?.report_status ? `-${reportData?.interpretation_data?.report_status}` : ''}`;
      downloadFromURI(uri, fileName)
      enqueueSnackbar("Pdf downloading successfully", { variant: 'success' })

    }
    catch (error) {
      enqueueSnackbar(error, { variant: 'error' })

    }

  }

  const downloadDefaultPdf = () => {
    try {
      if (pdfData) {
        const fileName = `ecg-report-${pdfData?.user_data?.first_name}${pdfData?.user_data?.last_name ? `-${pdfData?.user_data?.last_name}` : ''}-${dayjs(pdfData?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${pdfData?.interpretation_data?.report_status ? `-${pdfData?.interpretation_data?.report_status}` : ''}`;
        downloadFromURI(pdf, fileName)
      }
    }
    catch (e) {
      enqueueSnackbar(error, { variant: 'error' })

    }
  }


  return (
    <>
      <Box >
        <Grid sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginY: '10px',
          marginBottom: '20px',
          px: 1,
          flexDirection: (isSmall ? 'column-reverse' : 'row'),
          gap: (isSmall ? '1rem' : 0)
        }}>
          <Grid item xs={12} md={6}>
            <Box flexDirection="column" gap={2} display="flex" flexWrap="wrap" alignItems="center">
              {dataCount && <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
                <Typography variant="div" sx={{ marginBottom: 0 }} color="#2065D1" gutterBottom>
                  Requested: <b>{dataCount?.totalRequested || 0}</b>
                </Typography> |
                <Typography variant="div" sx={{ marginBottom: 0 }} color="green" gutterBottom>
                  Completed: <b>{dataCount?.totalCompleted || 0}</b>
                </Typography> |
                <Typography variant="div" sx={{ marginBottom: 0, fontWeight: 500 }} color="#FFC107" gutterBottom>
                  In-Progress: <b>{dataCount?.totalInprogress || 0}</b>
                </Typography>
              </Stack>}
              <Stack direction="row" spacing={1}>
                <Chip
                  label={`Requested`}
                  onClick={() => handleStatus('requested')}
                  color="primary"
                  variant={status === 'requested' ? "filled" : "outlined"}
                />
                <Chip
                  label={`Completed`}
                  onClick={() => handleStatus('completed')}
                  color="success"
                  variant={status === 'completed' ? "filled" : "outlined"}
                />
                <Chip
                  label={`In Progress`}
                  onClick={() => handleStatus('in progress')}
                  color="warning"
                  variant={status === 'in progress' ? "filled" : "outlined"}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }} >
              <Stack direction="row" spacing={1}>
                {(status === "completed" || status === '') && (<FormControl>
                  <InputLabel id="report_findings">Findings</InputLabel>
                  <Select
                    labelId="report-findings-label"
                    id="report-status-select"
                    value={interpretationStatusFilter}
                    label="Findings"
                    onChange={handleChange}
                    sx={{ width: (isSmall ? '80px' : (isMd ? '100px' : '120px')) }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="abnormal">Abnormal</MenuItem>
                    <MenuItem value="retake">Retake</MenuItem>
                  </Select>
                </FormControl>)}
                <DatePicker
                  sx={{ width: (isSmall ? '120px' : (isMd ? '120px' : '150px')) }}
                  label="Start Date"
                  value={startDate}
                  format='DD/MM/YYYY'
                  maxDate={dayjs(endDate)}
                  onChange={(date) => handleStartDateChange(date)}
                />
                <DatePicker
                  sx={{ width: (isSmall ? '120px' : (isMd ? '120px' : '150px')) }}
                  label="End Date"
                  value={endDate}
                  format='DD/MM/YYYY'
                  minDate={dayjs(startDate)}
                  maxDate={dayjs()}
                  onChange={(date) => handleEndDateChange(date)}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', marginTop: '30px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={!data ? { height: '100%', width: '100%' } : {}}>
          <DataGrid autoHeight={true}
            sx={{ m: 1, p: 1 }}
            rows={rows}
            filterMode="client"
            columns={TABLE_HEAD}
            disableVirtualization={true}
            getRowId={(row) => row?.id}
            getRowClicked={(row) => row?.id}
            onRowClick={(row, event) => handleOpenDialog(row?.id, event)}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            getRowClassName={(params) => {
              const isSelected = params.row.selected;
              const isTrue = params.row.isTrue;
              return isSelected || isTrue ? 'super-app-theme--Open' : ' ';
            }}
            localeText={{ noRowsLabel: 'No interpretation request found.' }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },

            }}
            hideFooter={!rows?.length}
            pageSizeOptions={[10, 15, 20, 25]}
            slots={{
              toolbar: () => (
                <>
                  <GridToolbarContainer>
                    <GridToolbarQuickFilter />
                    <Stack
                      spacing={3}
                      flexGrow={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <GridToolbarColumnsButton />
                      <GridToolbarFilterButton />
                      <GridToolbarExport csvOptions={{ fields: ["searchname", "searchreportType", "searchtakenAt", "searchrequestedAt", "searchfindings", "searchecg_status"] }} />
                    </Stack>
                  </GridToolbarContainer>
                </>
              ),
            }}
            sortModel={sortModel}
            onSortModelChange={(model) => handleSortModalChange(model)}
            sortingOrder={['asc', 'desc', null]}
          />
        </Box>
      )}

      <Dialog open={openViewPdfDialog} onClose={handleCloseViewPdfDialog} maxWidth="md" fullWidth
        PaperProps={{
          style: {
            overflow: 'hidden', // Remove scroll bar from the dialog box
          },
        }}
      >
        <DialogContent style={dialogStyles}>


          {pdf ? <div>
            <Stack flexDirection="row" display="flex" justifyContent="space-between" alignItems="center" width="100%" >
           

              <Tooltip title="Download" arrow>
              <LoadingButton
                variant="contained"
                sx={{ boxShadow: 'none' }} 
                onClick={() => downloadDefaultPdf()}
              >
                <Download />
              </LoadingButton>
            </Tooltip>
              {openLink && <Link variant="subtitle2" onClick={() => downloadAppPdf()}
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' }
                }}>
                Download app report
              </Link>}


              <Button onClick={handleCloseViewPdfDialog} variant="outlined" color="primary">
                Close
              </Button>

            </Stack>



            <div ref={documentRef} style={styles.documentBlock}>
              <TransformWrapper
                initialScale={1}
                smooth={true}
                wheel={{ step: 0.1, animationType: 'easeOut' }}
                pinch={{ step: 0.1, animationType: 'easeOut' }}
                doubleClick={{ step: 0.1, animationType: 'easeOut' }}
                onTransformed={(ref, scaleState) => {
                  handleZoom(scaleState?.scale)
                }} >
                <TransformComponent>
                  <Document
                    file={pdf}
                    onLoadSuccess={(data) => {
                      setTotalPages(data.numPages);

                    }}
                  >
                    {Array.apply(null, Array(totalPages))
                      .map((x, i) => i + 1)
                      .map(page => <Page
                        pageNumber={page}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        width={750}
                        scale={ pdfScale }
                      />)}

                  </Document>
                </TransformComponent>
              </TransformWrapper>

            </div>
          </div> : <CircularProgress></CircularProgress>}
        </DialogContent>

      </Dialog>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog} disableScrollLock={false}>
        <DialogTitle style={{ textAlign: 'center' }}>Report Information</DialogTitle>

        <DialogContent>
          {(selectedRowData && selectedRowData?.interpretation_data_logs && selectedRowData?.interpretation_data_logs?.length > 0) || (selectedRowData && selectedRowData?.meta_data) ? (

            <List>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="body1"><strong>Device Name:</strong> {metaData.device_name || 'N/A'}</Typography>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="body1"><strong>Device ID:</strong> {metaData.device_id || 'N/A'}</Typography>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="body1">
                    <strong>App Version:</strong> {metaData.app_version || 'N/A'}
                  </Typography>}
                />
              </ListItem>
              {selectedRowData?.interpretation_data_logs && selectedRowData?.interpretation_data_logs.length > 0 && (
                <List>
                  <ListItem>
                    <ListItemText
                      primary={<Typography variant="body1">
                        <strong>Interpretation History -:</strong>
                      </Typography>}
                    />
                  </ListItem>
                  {selectedRowData?.interpretation_data_logs?.map((item, index) => (
                    <ListItem key={index} style={{ paddingTop: '0 ' }}>
                      <ListItemText
                        primary={item?.interpreter_details?.name}
                        secondary={dayjs(item?.completed_at).format('DD MMM, YYYY hh:mm A')}
                      />
                    </ListItem>
                  ))}
                </List>

              )

              }

            </List>
          ) : (
            <Typography>No history available</Typography>
          )}

        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>

      </Dialog>


    </>
  )
}