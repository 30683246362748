import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Divider, Card, Stack, Typography, TextField, Button, FormControl, FormHelperText, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from '../components/snackbar';
import FormProvider from 'hooks/form/form-provider';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { saveInternalUsers } from 'api/users';
import {useAuthDispatch} from '../components/AuthStateProvider'

function UserProfile({ data, userType, isAdmin, isEditing, onSubmitDome }) {
    const { enqueueSnackbar } = useSnackbar(); 
    const { updateLocalStorage } = useAuthDispatch();
    const indianStates = [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
        'Andaman and Nicobar Islands',
        'Chandigarh',
        'Dadra and Nagar Haveli',
        'Daman and Diu',
        'Delhi',
        'Lakshadweep',
        'Puducherry',
    ];

    const defaultValues = data ? {
        ...data,
        user_type: (Array.isArray(userType) ? '-1' : userType),
    } : {
        user_type: (Array.isArray(userType) ? '-1' : userType),
        phone_number: '',
        name: '',
        email: '',
    };
    
    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });
    
    const {
        register,
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;
    
    const onSubmit = handleSubmit(async (formData) => {
        try {
            if (!Array.isArray(userType)) formData.user_type = userType;
            if (!data?.phone_number) formData.phone_number = `+91${formData?.phone_number}`;
            const response = await saveInternalUsers(formData)
            if (response.success === true && response.status == 200) {
                if (!isAdmin) {
                    // const updatedValues={...data, name: formData?.name, displayName: formData?.name, email: formData?.email }
                    // localStorage.setItem('TokenDetails',JSON.stringify(updatedValues))
                    const updatedValues = { name: formData?.name, displayName: formData?.name, email: formData?.email };
                    updateLocalStorage('TokenDetails', updatedValues);
                }
                enqueueSnackbar(response.message, { variant: 'success' })
                if (onSubmitDome) onSubmitDome(true)
            } else if (response.success === false) {
                enqueueSnackbar("Unable to save the data.", { variant: 'error' })
                if (onSubmitDome) onSubmitDome(null, true)
            }
        } catch (e) {
            console.log(e)
            enqueueSnackbar("Unable to save the data.", { variant: 'error' })
            if (onSubmitDome) onSubmitDome(null, true)
        }
    })

    return (
        <>
            <Card sx={{ py: 2, px: 3 }}>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Grid container spacing={1} justifyContent='center'> 
                    {isAdmin && <Grid item md={6}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="user-type-select-label">User Type</InputLabel>
                            <Controller
                                name="user_type"
                                control={control}
                                rules={{ validate: value => value !== '-1' || "Please select a valid user type." }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        disabled={ !Array.isArray(userType) && !isEditing }
                                        labelId="user-type-select-label"
                                        label="User Type"
                                        {...register("user_type", {
                                            validate: value => value !== '-1' || "Please select a valid user type."
                                        })}
                                        error={!!errors.user_type}>
                                        <MenuItem key="user_type" value="-1" disabled={ !Array.isArray(userType) }>Select user type</MenuItem>
                                        <MenuItem key="user_type_admin" value="admin" disabled={ !((Array.isArray(userType) && userType?.some(item => item === 'admin')) || userType === 'admin' || isEditing) }>Admin</MenuItem>
                                        <MenuItem key="user_type_expert" value="expert" disabled={ !((Array.isArray(userType) && userType?.some(item => item === 'admin')) || userType === 'admin' || isEditing) }>Expert</MenuItem>
                                        <MenuItem key="user_type_management_team" value="managementteam" disabled={ !((Array.isArray(userType) && userType?.some(item => item === 'managementteam')) || userType === 'managementteam' || isEditing) }>Management Team</MenuItem>
                                        <MenuItem key="user_type_business_team" value="businessteam" disabled={ !((Array.isArray(userType) && userType?.some(item => item === 'businessteam')) || userType === 'businessteam' || isEditing) }>Business Team</MenuItem>
                                        <MenuItem key="user_type_marketing_team" value="marketingteam" disabled={ !((Array.isArray(userType) && userType?.some(item => item === 'marketingteam')) || userType === 'marketingteam' || isEditing) }>Marketing Team</MenuItem>
                                        <MenuItem key="user_type_r&dteam_team" value="r&dteam" disabled={ !((Array.isArray(userType) && userType?.some(item => item === 'r&dteam')) || userType === 'r&dteam' || isEditing) }>R & D Team</MenuItem>
                                    </Select>
                                )} />
                                {errors.user_type && <FormHelperText style={{ color: 'red' }}>{errors.user_type.message}</FormHelperText>}
                            </FormControl>
                        </Grid> }
                        <Grid item md={6}>
                            <Controller
                                name="phone_number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        disabled={ data?.phone_number }
                                        sx={{ minWidth: 300 }}
                                        label="Phone number"
                                        fullWidth
                                        placeholder='9999999999'
                                        margin="normal"
                                        {...register('phone_number', {
                                            required: "Please enter the phone number.",
                                            pattern: {
                                                value: !data?.phone_number ? /^[0-9]{10}$/i : '',
                                                message: 'Invalid phone number'
                                            }
                                        })}
                                        error={!!errors.phone_number}
                                        helperText={errors.phone_number?.message}
                                        InputProps={ !data?.phone_number ? {
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                +91
                                            </InputAdornment>
                                            ),
                                        } : null }
                                    />
                                )} />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field} 
                                        sx={{ minWidth: 300 }}
                                        label="Name"
                                        fullWidth
                                        margin="normal"
                                        {...register('name', {
                                            required: "Please enter your name.",
                                        })}
                                        error={!!errors.name}
                                        helperText={errors.name?.message}
                                    />
                            )} />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ minWidth: 300 }}
                                        label="Email"
                                        fullWidth
                                        margin="normal"
                                        {...register('email', {
                                            required: "Please enter your email.",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'Invalid email address'
                                            }
                                        })}
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                )} />
                        </Grid>


                        <Grid item xs={12} textAlign='center'>
                            <Divider sx={{ my: 2 }} />
                            <LoadingButton
                                sx={{ minWidth: 150 }}
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </Card>
        </>
    )
}

export default UserProfile;