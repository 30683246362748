import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Label from 'components/label/Label';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';

// @mui
import {
    Card,
    Box,
    Table,
    Stack,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableFooter,
    Tooltip,
    Link,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Divider,
    TextField,
    InputAdornment
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useTheme } from '@mui/material/styles';

import Scrollbar from '../components/scrollbar';

import { styled } from '@mui/system';
import {useSnackbar} from '../components/snackbar';
import { Add, Close, Edit } from '@mui/icons-material'
import { getB2BAssociatedUser, addB2BAssociatedUser, removeB2BAssociatedUser } from 'api/users';
import { copyToClipboard, downloadFromURI } from 'utils/Utils';
import { useAuthDispatch } from 'components/AuthStateProvider';
import { VerifyPhoneNo } from 'sections/auth/login';
//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function ManageAssociatedUsers() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar(); 
  const isSmall = useResponsive('', 'xs');
  const isLg = useResponsive('up', 'md');
  const { account } = useAuthDispatch();

  const [openAddUser, setOpenAddUser] = useState(null);
  const [deleteData, setDeleteData] = useState(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getB2BAssociatedUser();
      setLoading(false);
      if (response?.success) {
        setData(response?.data || []);
      } else {
        enqueueSnackbar(response.message, { variant: 'error' })
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const onDeleteDataClick = (data) => async (e) => {
    try {
        if (deleteData) {
          setIsSubmitting(true);
          const response = await removeB2BAssociatedUser({ phone_numbers: [deleteData?.phone_number] });
          setIsSubmitting(false);
          if (response?.success) {
            setDeleteData(null);
            fetchData();
          } else {
            enqueueSnackbar(response.message, { variant: 'error' })
          }
        } else {
          setIsSubmitting(false);
          enqueueSnackbar("Please select user to delete.", { variant: 'error' })
        }
      } catch (e) {
        setIsSubmitting(false);
        enqueueSnackbar(e.message || "Unable to delete the user.", { variant: 'error' })
        console.log(e)
      }
  }

  const onAddNewDone = (e, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") return false;
    setOpenAddUser(false);
  }

  const onCopyClick = (text) => (e) => {
    copyToClipboard(text);
    enqueueSnackbar('Copied to clipboard.', { variant: 'success' })
  };

  const handleCloseDialog = async (isVerified, phoneNumber) => {
    if (isVerified) {
      const response= await addB2BAssociatedUser({ phone_numbers: [`${phoneNumber}`] });
      if(response.success){
          enqueueSnackbar(response.message || 'Added sucessfully.', { variant: 'success' })
          fetchData();
      } else{
          enqueueSnackbar(response.message || 'Unable to add user.', { variant: 'error' })
      }
    }
    setOpenAddUser(false);
  };

  return (
    <>
      <Helmet>
        <title> Manage Your User | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: (isLg ? 0 : 1) }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Manage associated users
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={() => { setOpenAddUser(true); }}>
              <Add sx={{ mr: 1 }} />
                Add User
            </Button> 
          </Stack>
        </Stack>
        <Card>
          <Box>
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Scrollbar>
                  {/* <TableContainer sx={{ minWidth: 800 }}> */}
                  <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: 150 }}>Name</TableCell>
                            <TableCell sx={{ minWidth: 100 }}>Phone No.</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                    {data.length ? (
                      <TableBody>
                        {data?.map((row, index) => {
                          return (
                            <TableRow hover key={index} tabIndex={-1}>
                              <TableCell>
                                <OverflowTypography variant="subtitle2" noWrap>
                                    {row?.name}
                                </OverflowTypography>
                              </TableCell>
                              <TableCell>
                                <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                  {row?.phone_number}
                                </OverflowTypography>
                              </TableCell>
                              <TableCell>
                                  <Stack direction="row"  spacing={2}>
                                    <Tooltip title="Edit" arrow>
                                        <LoadingButton
                                            onClick={ () => setDeleteData(row) }
                                            loading={false}
                                            variant="contained"
                                            color='error'
                                            disabled={ deleteData?.phone_number === row?.phone_number }
                                            >
                                            Delete
                                        </LoadingButton>
                                    </Tooltip>
                                  </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableFooter>
                        <TableRow>
                          <TableCell style={{ width: '100%' }} align="center" colSpan={6} v>
                            <Typography mt={2}>No user found.</Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                  {/* </TableContainer> */}
                </Scrollbar>
              )}
            </Box>
          </Box>
        </Card>
      </Container>
      <Dialog
        fullWidth
        maxWidth="xs"
        scroll='body'
        // disableEscapeKeyDown
        disableBackdropClick={true}
        open={ openAddUser }
        onClose={ onAddNewDone }
        sx={{padding:'20px'}}
      >
        <VerifyPhoneNo onVerify={handleCloseDialog}/>
        
      </Dialog>

      { deleteData && <Dialog
        fullWidth
        maxWidth="xs"
        scroll='body'
        disableEscapeKeyDown={()=> setDeleteData(null)}
        open={deleteData ? true : false}
        onClose={()=> setDeleteData(null)}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Confirmation
        </DialogTitle>
        <Box sx={{ px: 3, pb: 4 }}>Do you want to delete user <b>{deleteData?.name}</b>?</Box>
        <Divider />
        <DialogActions>
          <Stack direction='row' spacing={4} sx={{ px: 2, py: 1 }}>
            <Button variant="outlined" color="error" onClick={ () => setDeleteData(null) }>
              Cancel
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={ onDeleteDataClick(deleteData) }
            >
              Confirm
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog> }
    </>
  );
}
