import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import { DataGrid} from '@mui/x-data-grid';
// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableFooter,
  Tooltip,
  Link,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Divider
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// components
import Scrollbar from '../components/scrollbar';

import { styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';

import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { getAppOnboardedUsers } from 'api/users';
import { copyToClipboard } from 'utils/Utils';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function AppOnboardedUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useResponsive('', 'xs');
  const isLg = useResponsive('up', 'md');

  const [{ startDate, endDate }, setDates] = useState({ startDate: dayjs(), endDate: dayjs() });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const fetchData = async () => {
    try {
      const response = await getAppOnboardedUsers({ startDate: dayjs(startDate).format("DD MMM, YYYY"), endDate: dayjs(endDate).format("DD MMM, YYYY") });
      
      
      setLoading(false);
      if (response?.success) {
        const usersData = response?.data?.map((user, index) => {
          return {
            ...user,
            sno: index + 1,
            userEmail: user?.userEmail || "--",
            name: user?.firstName + (user?.lastName ? " " + user?.lastName : ""),
            created_at: user?.created_at ? dayjs(parseInt(user?.created_at)).format("DD MMM, YYYY hh:mm A") : "",
          }
        })
        setData(usersData);
      } else {
        enqueueSnackbar(response?.message, { variant: 'error' })
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Unable to fetch users.", { variant: 'error' })
    }
  };

  const onCopyClick = (text) => (e) => {
    copyToClipboard(text);
    enqueueSnackbar('Copied to clipboard.', { variant: 'success' })
  };

  const downloadCSV = async (e) => {
    setDownloading(true);
    
    if (data?.length) {
      const users = data?.map(user => ({
        'Name': user?.name,
        'Phone No': user?.phoneNumber,
        'Email': user?.userEmail,
        'Created At': user?.created_at,
      }));

      const fileName = `users-onboarded-on-app-date-${startDate !== endDate ? dayjs(startDate).format("DD-MM-YY") + "_to_" + dayjs(endDate).format("DD-MM-YY") : dayjs(startDate).format("DD-MM-YY") }`
      const exportType = exportFromJSON.types.csv
      exportFromJSON({ data: users, fileName, exportType });
    }
    setDownloading(false);
  }

  const handleStartDateChange = (sDate, eDate) => {
    if (sDate) setDates({ startDate: sDate, endDate  })
    if (eDate) setDates({ startDate, endDate: eDate })
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'sno', headerName: '#', hide: true, width: 50 },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone No',
      width: 180,
    },
    {
      field: 'userEmail',
      headerName: 'Email',
      width: 300,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
    },
  ];
  return (
    <>
      <Helmet>
        <title>Onboarded users on APP | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Stack direction="column" >
                <Typography variant="h4" gutterBottom>
                    Onboarded users on APP
                </Typography>
                { data?.length ? <Typography variant="div" gutterBottom>
                    Users onboarded: <b>{ data?.length }</b>
                </Typography> : null }
            </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" spacing={1}>
                <DatePicker
                    sx={{ width: '160px' }}
                    label="Start Date"
                    value={ startDate }
                    format='DD/MM/YYYY'
                    maxDate={endDate}
                    onChange={(date) => handleStartDateChange(date)}
                />
                <DatePicker
                    sx={{ width: '160px' }}
                    label="End Date"
                    value={ endDate }
                    format='DD/MM/YYYY'
                    minDate={ startDate }
                    maxDate={ dayjs() }
                    onChange={(date) => handleStartDateChange(null, date)}
                />
            </Stack>
            <Box>
                <LoadingButton
                    loading={ downloading }
                    color="info"
                    variant="contained"
                    onClick={ downloadCSV }

                    sx={{ borderRadius: "20px" }}>
                    Download CSV
                </LoadingButton>
            </Box>
          </Stack>
        </Stack>
        <Card>
          <Box>
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <DataGrid
                  columnVisibilityModel={{
                    id: false,
                  }}
                  rows={data}
                  columns={ columns }
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25]}
                 
                />
                // <Scrollbar>
                //   {/* <TableContainer sx={{ minWidth: 800 }}> */}
                //   <Table>
                //     <TableHead>
                //       <TableRow>
                //         <TableCell sx={{ minWidth: 150 }}>Name</TableCell>
                //         <TableCell sx={{ minWidth: 150 }}>Phone Number</TableCell>
                //         <TableCell sx={{ minWidth: 100 }}>Email</TableCell>
                //         <TableCell sx={{ minWidth: 100 }}>Created At</TableCell>
                //       </TableRow>
                //     </TableHead>
                //     {data.length ? (
                //       <TableBody>
                //         {data?.map((row, index) => {
                //           return (
                //             <TableRow hover key={index} tabIndex={-1}>
                //               <TableCell>
                //                 <Stack direction="row" alignItems="center" spacing={2}>
                //                     <OverflowTypography variant="subtitle2" noWrap>
                //                         { row?.firstName + (row?.lastName ? " " + row?.lastName : "") }
                //                     </OverflowTypography>
                //                 </Stack>
                //               </TableCell>
                //               <TableCell>
                //                 <Stack direction="row" alignItems="center" spacing={2}>
                //                     <OverflowTypography variant="subtitle2" noWrap>
                //                         {row?.phoneNumber}
                //                     </OverflowTypography>
                //                 </Stack>
                //               </TableCell>
                //               <TableCell>
                //                 <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                //                   {row?.userEmail || "--"}
                //                 </OverflowTypography>
                //               </TableCell>
                //               <TableCell>
                //                 { row?.created_at ? dayjs(parseInt(row?.created_at)).format("DD MMM, YYYY hh:mm A") : "--" }
                //               </TableCell>
                //             </TableRow>
                //           );
                //         })}
                //       </TableBody>
                //     ) : (
                //       <TableFooter>
                //         <TableRow>
                //           <TableCell style={{ width: '100%' }} align="center" colSpan={6} v>
                //             <Typography mt={2}>No user found.</Typography>
                //           </TableCell>
                //         </TableRow>
                //       </TableFooter>
                //     )}
                //   </Table>
                //   {/* </TableContainer> */}
                // </Scrollbar>
              )}
            </Box>
          </Box>
        </Card>
      </Container>
    </>
  );
}
