import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import { DataGrid, GridToolbarExport,GridToolbarContainer} from '@mui/x-data-grid';
// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableFooter,
  Tooltip,
  Link,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Divider
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// components
import Scrollbar from '../components/scrollbar';

import { styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';

import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { getGuestUsers } from 'api/users';
import { copyToClipboard } from 'utils/Utils';
import minMax from 'dayjs/plugin/minMax'; // Import the minMax plugin

// Extend dayjs with the minMax plugin
dayjs.extend(minMax);

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function GuestUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useResponsive('', 'xs');
  const isLg = useResponsive('up', 'md');

  const [{ startDate, endDate }, setDates] = useState({ startDate: dayjs(), endDate: dayjs() });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [searching,setSearching]=useState(false);
  const [totalGuestUser,setTotalGuestUser]=useState();
 

  const today = dayjs();


  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    try {
      const response = await getGuestUsers({ startDate: dayjs(startDate).format("DD MMM, YYYY"), endDate: dayjs(endDate).format("DD MMM, YYYY")});
      
      setLoading(false);
      setSearching(false);
      if (response?.success ) {
        setTotalGuestUser(response?.data?.totalReport)
       
        const usersData = response?.data?.guestUsers  // Only process users with a guest phone number
        .map((user, index) => ({
          ...user,
          sno: index + 1,
          name: user?.user_data?.first_name + (user?.user_data?.last_name ? " " + user?.user_data?.last_name : ""),
          phoneNumber: user?.user_data?.guest_phone_number || "--",
          takenAt: user?.report_timestamp? dayjs(parseInt(user?.report_timestamp)).format("DD MMM, YYYY hh:mm A") : "",
          reportType:user?.report_type?.replace(/REPORT|_/g, ' ') || "--",
          reportDetection:user?.conclusions?.ecg_type || "--",
          deviceId: user?.device_id || "--",
        }));
          
        setData(usersData);
      } else {
        enqueueSnackbar(response?.message, { variant: 'error' })
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Unable to fetch users.", { variant: 'error' })
    }
  };

 

  const handleStartDateChange = (sDate, eDate) => {
    if (sDate) setDates({ startDate: sDate, endDate:dayjs.min(today,sDate.add(7,'day') ) })
    if (eDate) setDates({ startDate:eDate.subtract(7,'day'), endDate: eDate })
  } 
 const fetchUserInfo = async() => {
     setSearching(true);
     try {
        await fetchData();
      } finally {
        setSearching(false); // Stop loading, regardless of success or failure
      }

  }


const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'sno', headerName: '#', hide: true, width: 50 },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone No',
      width: 180,
    },
    {
      field: 'takenAt',
      headerName: 'Taken At',
      width: 180,
    },
    {
      field: 'reportType',
      headerName: 'Report Type',
      width: 180,
    }, 
    {
      field: 'reportDetection',
      headerName: 'Report Detection',
      width: 180,
    },

    {
      field: 'deviceId',
      headerName: 'Device Id',
      width: 100,
    },

  ];
  
return (
    <>
      <Helmet>
        <title>
            Guest users on APP | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Stack direction="column" >
                <Typography variant="h4" gutterBottom>
                Guest users on APP
                </Typography>
                { totalGuestUser ? <Typography variant="div" gutterBottom>
                    Total Test Performed: <b>{ totalGuestUser }</b>
                </Typography> : null }
                { data?.length ? <Typography variant="div" gutterBottom>
                    Having Contact: <b>{ data?.length }</b>
                </Typography> : null }
                
            </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center">
                <DatePicker
                    sx={{ width: '160px' }}
                    label="Start Date"
                    value={ startDate }
                    format='DD/MM/YYYY'
                    
                    maxDate={dayjs(endDate)}
                    
                    onChange={(date) => handleStartDateChange(date,null)}
                />
                <DatePicker
                    sx={{ width: '160px' }}
                    label="End Date"
                    value={ endDate }
                    format='DD/MM/YYYY'
                    minDate={dayjs(startDate)}
                    
                    maxDate={today}
                    onChange={(date) => handleStartDateChange(null,date)}
                />
                <Box>
                <LoadingButton
                    loading={searching}
                    color="info"
                    variant="contained"
                    onClick={ fetchUserInfo }
                    sx={{ borderRadius: "20px" }}>
                    Search
                </LoadingButton>
            </Box>
            </Stack>
            
          
          </Stack>
        </Stack>
        <Card>
          <Box>
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) :  data && data.length>0 ?(
                <DataGrid
                  columnVisibilityModel={{
                    id: false,
                  }}
                  rows={data}
                  columns={ columns }
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[25]}
                  slots={{
                    toolbar: () => (
                      <>
                        <GridToolbarContainer>
                         
                          <Stack
                           
                            flexGrow={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                             
                           
                            <GridToolbarExport csvOptions={{ fields: ["name", "phoneNumber", "takenAt", "reportType", "reportDetection", "deviceId"] }} />
                          </Stack>
                        </GridToolbarContainer>
                      </>
                    ),
                  }}
                />
                
              ) :(
                <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
                  <Typography variant="h6">No data found</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Card>
      </Container>
    </>
  );
}
